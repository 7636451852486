import Vue from 'vue'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (SENTRY_PUBLIC_DSN) {
  Sentry.init({
    dsn: SENTRY_PUBLIC_DSN,
    debug: NODE_ENV !== 'production',
    release: REVISION,
    integrations: [new Integrations.Vue({ Vue })],
  })
  Sentry.configureScope((scope) => {
    scope.setTag('logger', 'javascript')
    scope.setUser({ id: window.ENV.CustomerUuid })
  })
}
